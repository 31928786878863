import * as Y from 'yjs'
import { HocuspocusProvider } from '@hocuspocus/provider'

// Set up the provider
const provider = new HocuspocusProvider({
  url: "wss://hp.sv.tuba.live",
  name: "awareness-example",
  document: new Y.Doc(),
  // Listen for updates …
  onAwarenessUpdate: ({ states }) => {
    console.log(states);
    updateUsersList(states);
    updateChatList(states);
  },
});

// Listen for name input
const nameInput = document.getElementById('name-input');
nameInput.addEventListener('input', () => {
  const name = nameInput.value.trim();
  if (name !== '') {
    // Share name
    provider.setAwarenessField("user", {
      name: name,
    });
  }
});

// Update user list when awareness updates
const usersList = document.getElementById('users');
function updateUsersList(states) {
  usersList.innerHTML = '';
  for (const clientID in states) {
    const { user } = states[clientID];
    if (user && user.name) {
      const listItem = document.createElement('li');
      listItem.textContent = user.name;
      usersList.appendChild(listItem);
    }
  }
}

// Send chat message
const chatInput = document.getElementById('chat-input');
const chatList = document.getElementById('chat-list');
chatInput.addEventListener('keydown', (event) => {
  if (event.key === 'Enter') {
    const message = chatInput.value.trim();
    if (message !== '') {
      provider.setAwarenessField("chat", {
        name: nameInput.value.trim(),
        message: message,
      });
      chatInput.value = '';
    }
  }
});

// const sendBtn = document.getElementById('send-btn');
// sendBtn.addEventListener('click', () => {
//   const message = chatInput.value.trim();
//   if (message !== '') {
//     provider.setAwarenessField("chat", {
//       name: nameInput.value.trim(),
//       message: message,
//     });
//     chatInput.value = '';
//   }
// });

// Listen for chat messages
function updateChatList(states) {
  chatList.innerHTML = '';
  for (const clientID in states) {
    const { chat } = states[clientID];
    if (chat && chat.name && chat.message) {
      const listItem = document.createElement('li');
      listItem.textContent = chat.name + ': ' + chat.message;
      chatList.appendChild(listItem);
    }
  }
}

